import axios from "axios";
import * as backendModule from "../../modules/backendModule";

import * as JsSIP from "jssip";
import * as PropTypes from "prop-types";
import * as React from "react";
import dummyLogger from "react-sip/dist/lib/dummyLogger";
import { useDispatch } from "react-redux";
import * as sipActions from "../../actions/sipActions";
import * as modalActions from "../../actions/modalActions";

import SIPCallModal from "../ModalController/modals/SIPCallModal";

import {
    CALL_DIRECTION_INCOMING,
    CALL_DIRECTION_OUTGOING,
    CALL_STATUS_ACTIVE,
    CALL_STATUS_IDLE,
    CALL_STATUS_STARTING,
    CALL_STATUS_STOPPING,
    SIP_ERROR_TYPE_CONFIGURATION,
    SIP_ERROR_TYPE_CONNECTION,
    SIP_ERROR_TYPE_REGISTRATION,
    SIP_STATUS_CONNECTED,
    SIP_STATUS_CONNECTING,
    SIP_STATUS_DISCONNECTED,
    SIP_STATUS_ERROR,
    SIP_STATUS_REGISTERED,
} from "react-sip/dist/lib/enums";
import {
    CallDirection,
    CallStatus,
    SipErrorType,
    SipStatus,
} from "react-sip/dist/lib/enums";
import {
    callPropType,
    ExtraHeaders,
    extraHeadersPropType,
    IceServers,
    iceServersPropType,
    sipPropType,
} from "react-sip/dist/lib/types";

let registerSip = () => { };
let unregisterSip = () => { };
let answerCall = () => { };
let sendDTMF = (tone, duration) => { };
/**
* 
* @param {string} destination 
* @param {"dial" | "lead" | "deal"} type 
* @param {string | null} cID 
* @param {string | null} usrID 
* @param {string | null} HandshakeID
*/
let startCall = (destination, type = "dial", cID, usrID) => { };
let stopCall = () => { };
let hangupCall = () => { };

let rtcList = [];
const rtcOn = (name, cb) => {
    rtcList.push({
        name, cb
    });
};
const rtcOff = (name, cb) => rtcList = rtcList.filter(t => t.name !== name && t.cb !== cb);
const fireRTCEvent = (name, value) => rtcList.filter(t => t.name === name).forEach(t => t?.cb(value));

let uaList = [];
const uaOn = (name, cb) => {
    uaList.push({
        name, cb
    });
};
const uaOff = (name, cb) => uaList = uaList.filter(t => t.name !== name && t.cb !== cb);
const fireUAEvent = (name, value) => uaList.filter(t => t.name === name).forEach(t => t?.cb(value));

let canAcceptIncomming = true;

const setAcceptIncomming = (newState = true) => canAcceptIncomming = newState;

class SipProvider extends React.Component {
    static childContextTypes = {
        sip: sipPropType,
        call: callPropType,
        registerSip: PropTypes.func,
        unregisterSip: PropTypes.func,

        answerCall: PropTypes.func,
        startCall: PropTypes.func,
        stopCall: PropTypes.func,
    };

    static propTypes = {
        host: PropTypes.string,
        port: PropTypes.number,
        pathname: PropTypes.string,
        user: PropTypes.string,
        password: PropTypes.string,
        autoRegister: PropTypes.bool,
        autoAnswer: PropTypes.bool,
        iceRestart: PropTypes.bool,
        sessionTimersExpires: PropTypes.number,
        extraHeaders: extraHeadersPropType,
        iceServers: iceServersPropType,
        debug: PropTypes.bool,

        children: PropTypes.node,
    };

    static defaultProps = {
        host: null,
        port: null,
        pathname: "",
        user: null,
        password: null,
        autoRegister: true,
        autoAnswer: false,
        iceRestart: false,
        sessionTimersExpires: 120,
        extraHeaders: { register: [], invite: [] },
        iceServers: [],
        debug: false,

        children: null,
    };
    ua;
    remoteAudio;
    logger;

    constructor(props) {
        super(props);

        this.state = {
            sipStatus: SIP_STATUS_DISCONNECTED,
            sipErrorType: null,
            sipErrorMessage: null,

            rtcSession: null,
            // errorLog: [],
            callStatus: CALL_STATUS_IDLE,
            callDirection: null,
            callCounterpart: null,
        };

        this.ua = null;
    }

    getChildContext() {
        return {
            sip: {
                ...this.props,
                status: this.state.sipStatus,
                errorType: this.state.sipErrorType,
                errorMessage: this.state.sipErrorMessage,
            },
            call: {
                id: "??",
                status: this.state.callStatus,
                direction: this.state.callDirection,
                counterpart: this.state.callCounterpart,
            },
            registerSip: this.registerSip,
            unregisterSip: this.unregisterSip,

            answerCall: this.answerCall,
            startCall: this.startCall,
            stopCall: this.stopCall,
        };
    }

    componentDidMount() {
        registerSip = this.registerSip;
        unregisterSip = this.unregisterSip;
        answerCall = this.answerCall;
        startCall = this.startCall;
        stopCall = this.stopCall;

        if (window.document.getElementById("sip-provider-audio")) {
            throw new Error(
                `Creating two SipProviders in one application is forbidden. If that's not the case ` +
                `then check if you're using "sip-provider-audio" as id attribute for any existing ` +
                `element`,
            );
        }

        this.remoteAudio = window.document.createElement("audio");
        this.remoteAudio.id = "sip-provider-audio";
        window.document.body.appendChild(this.remoteAudio);

        this.reconfigureDebug();
        this.reinitializeJsSIP();
    }

    componentDidUpdate(prevProps) {
        if (this.props.debug !== prevProps.debug) {
            this.reconfigureDebug();
        }
        if (
            this.props.host !== prevProps.host ||
            this.props.port !== prevProps.port ||
            this.props.pathname !== prevProps.pathname ||
            this.props.user !== prevProps.user ||
            this.props.password !== prevProps.password ||
            this.props.autoRegister !== prevProps.autoRegister
        ) {
            this.reinitializeJsSIP();
        }
    }

    componentWillUnmount() {
        this.remoteAudio.parentNode.removeChild(this.remoteAudio);
        delete this.remoteAudio;
        if (this.ua) {
            this.ua.stop();
            this.ua = null;
        }
    }

    registerSip = () => {
        if (this.props.autoRegister) {
            throw new Error(
                "Calling registerSip is not allowed when autoRegister === true",
            );
        }
        if (this.state.sipStatus !== SIP_STATUS_CONNECTED) {
            throw new Error(
                `Calling registerSip is not allowed when sip status is ${this.state.sipStatus
                } (expected ${SIP_STATUS_CONNECTED})`,
            );
        }
        return this.ua.register();
    };

    unregisterSip = () => {
        if (this.props.autoRegister) {
            throw new Error(
                "Calling registerSip is not allowed when autoRegister === true",
            );
        }
        if (this.state.sipStatus !== SIP_STATUS_REGISTERED) {
            throw new Error(
                `Calling unregisterSip is not allowed when sip status is ${this.state.sipStatus
                } (expected ${SIP_STATUS_CONNECTED})`,
            );
        }
        return this.ua.unregister();
    };

    answerCall = (usrID = null) => {
        if (
            this.state.callStatus !== CALL_STATUS_STARTING ||
            this.state.callDirection !== CALL_DIRECTION_INCOMING
        ) {
            throw new Error(
                `Calling answerCall() is not allowed when call status is ${this.state.callStatus
                } and call direction is ${this.state.callDirection
                }  (expected ${CALL_STATUS_STARTING} and ${CALL_DIRECTION_INCOMING})`,
            );
        }
        this.state.rtcSession.answer({
            mediaConstraints: {
                audio: true,
                video: false,
            },
            pcConfig: {
                iceServers: this.props.iceServers,
            },
            extraHeaders: [
                "X-TYPE: dial",
                `X-USRID: ${usrID}`
            ]
        });
    };

    /**
     * 
     * @param {string} destination 
     * @param {"dial" | "lead" | "deal"} type 
     * @param {string | null} cID 
     * @param {string | null} usrID 
     * @param {string | null} HandshakeID
     */
    startCall = (destination, type = "dial", cID = null, usrID = null, HandshakeID = null) => {
        if (!destination) {
            throw new Error(`Destination must be defined (${destination} given)`);
        }
        if (
            this.state.sipStatus !== SIP_STATUS_CONNECTED &&
            this.state.sipStatus !== SIP_STATUS_REGISTERED
        ) {
            throw new Error(
                `Calling startCall() is not allowed when sip status is ${this.state.sipStatus
                } (expected ${SIP_STATUS_CONNECTED} or ${SIP_STATUS_REGISTERED})`,
            );
        }

        if (this.state.callStatus !== CALL_STATUS_IDLE) {
            throw new Error(
                `Calling startCall() is not allowed when call status is ${this.state.callStatus
                } (expected ${CALL_STATUS_IDLE})`,
            );
        }

        const { iceServers, sessionTimersExpires } = this.props;
        const extraHeaders = this.props.extraHeaders.invite;

        const options = {
            extraHeaders: [
                ...extraHeaders
            ],
            eventHandlers: {
                "sending": e => {
                    if (e?.request.headers?.From?.length > 0) {
                        e.request.headers.From = e.request.headers.From.map(t => {
                            if (type) t += `;XTYPE=${type}`;
                            if (cID) t += `;XCID=${cID}`;
                            if (usrID) t += `;XUSRID=${usrID}`;
                            if (HandshakeID) t += `;XHANDSHAKEID=${HandshakeID}`;
                            return t;
                        });
                    };
                    if (e?.request.headers?.To?.length > 0) {
                        e.request.headers.To = e.request.headers.To.map(t => {
                            if (type) t += `;XTYPE=${type}`;
                            if (cID) t += `;XCID=${cID}`;
                            if (usrID) t += `;XUSRID=${usrID}`;
                            if (HandshakeID) t += `;XHANDSHAKEID=${HandshakeID}`;
                            return t;
                        });
                    };
                }
            },
            mediaConstraints: { audio: true, video: false },
            rtcOfferConstraints: { iceRestart: this.props.iceRestart },
            pcConfig: {
                iceServers,
            },
            sessionTimersExpires,
        };

        let session = this.ua.call(destination, options);
        if (session.connection) {
            session.connection.addEventListener('addstream', e => {
                this.remoteAudio.srcObject = e.stream;
                this.remoteAudio.play();
            });
        };
        this.setState({ callStatus: CALL_STATUS_STARTING });
    };

    stopCall = () => {
        this.setState({ callStatus: this.state.callStatus === CALL_STATUS_ACTIVE ? CALL_STATUS_STOPPING : CALL_STATUS_IDLE });
        this.ua.terminateSessions();
    };

    reconfigureDebug() {
        const { debug } = this.props;

        if (debug) {
            JsSIP.debug.enable("JsSIP:*");
            this.logger = console;
        } else {
            JsSIP.debug.disable("JsSIP:*");
            this.logger = dummyLogger;
        }
    }

    reinitializeJsSIP() {
        if (this.ua) {
            this.ua.stop();
            this.ua = null;
        }

        const { host, port, pathname, user, password, autoRegister, proxyHost } = this.props;

        if (!host || !port || !user) {
            this.setState({
                sipStatus: SIP_STATUS_DISCONNECTED,
                sipErrorType: null,
                sipErrorMessage: null,
            });
            return;
        }

        try {
            const socket = new JsSIP.WebSocketInterface(
                `wss://${host}:${port}${pathname}`
            );
            this.ua = new JsSIP.UA({
                uri: `sip:${user}@${proxyHost ?? host}`,
                password,
                sockets: [socket],
                register: autoRegister,
                session_timers_force_refresher: true,
                register_expires: 3600
            });
        } catch (error) {
            this.logger.debug("Error", error.message, error);
            this.setState({
                sipStatus: SIP_STATUS_ERROR,
                sipErrorType: SIP_ERROR_TYPE_CONFIGURATION,
                sipErrorMessage: error.message,
            });
            return;
        }

        const { ua } = this;

        ua.on("connecting", () => {
            fireUAEvent("connecting", null);
            this.logger.debug('UA "connecting" event');
            if (this.ua !== ua) {
                return;
            }
            this.setState({
                sipStatus: SIP_STATUS_CONNECTING,
                sipErrorType: null,
                sipErrorMessage: null,
            });
        });

        ua.on("connected", () => {
            fireUAEvent("connected", null);
            this.logger.debug('UA "connected" event');
            if (this.ua !== ua) {
                return;
            }
            this.setState({
                sipStatus: SIP_STATUS_CONNECTED,
                sipErrorType: null,
                sipErrorMessage: null,
            });
        });

        ua.on("disconnected", (e) => {
            fireUAEvent("disconnected", e);
            this.logger.debug('UA "disconnected" event');
            if (this.ua !== ua) {
                return;
            }
            this.setState({
                sipStatus: SIP_STATUS_ERROR,
                sipErrorType: SIP_ERROR_TYPE_CONNECTION,
                sipErrorMessage: "disconnected",
            });
        });

        ua.on("registered", (data) => {
            fireUAEvent("registered", data);
            this.logger.debug('UA "registered" event', data);
            if (this.ua !== ua) {
                return;
            }
            this.setState({
                sipStatus: SIP_STATUS_REGISTERED,
                callStatus: CALL_STATUS_IDLE,
            });
        });

        ua.on("unregistered", () => {
            fireUAEvent("unregistered", null);
            this.logger.debug('UA "unregistered" event');
            if (this.ua !== ua) {
                return;
            }
            if (ua.isConnected()) {
                this.setState({
                    sipStatus: SIP_STATUS_CONNECTED,
                    callStatus: CALL_STATUS_IDLE,
                    callDirection: null,
                });
            } else {
                this.setState({
                    sipStatus: SIP_STATUS_DISCONNECTED,
                    callStatus: CALL_STATUS_IDLE,
                    callDirection: null,
                });
            }
        });

        ua.on("registrationFailed", (data) => {
            fireUAEvent("registrationFailed", data);
            this.logger.debug('UA "registrationFailed" event');
            if (this.ua !== ua) {
                return;
            }
            this.setState({
                sipStatus: SIP_STATUS_ERROR,
                sipErrorType: SIP_ERROR_TYPE_REGISTRATION,
                sipErrorMessage: data,
            });
        });
        //simulacija dolaznog poziva (incoming)
        //this.props.dispatch(modalActions.addModal(<SIPCallModal type="incomming" phone={38766484455} />));
        ua.on(
            "newRTCSession",
            async ({ originator, session: rtcSession, request: rtcRequest }) => {
                if (!this || this.ua !== ua) {
                    return;
                }
                if (this.state?.rtcSession) {
                    this.logger.debug('incoming call replied with 486 "Busy Here"');
                    rtcSession.terminate({
                        status_code: 486,
                        reason_phrase: "Busy Here",
                    });
                    return;
                }
                if (this.state.callStatus !== CALL_STATUS_IDLE) {
                    return rtcSession?.terminate({
                        status_code: 486,
                        reason_phrase: "Busy Here",
                    });
                };
                // identify call direction
                if (originator === "local") {
                    const foundUri = rtcRequest.to.toString();
                    const delimiterPosition = foundUri.indexOf(";") || null;
                    this.setState({
                        callDirection: CALL_DIRECTION_OUTGOING,
                        callStatus: CALL_STATUS_STARTING,
                        callCounterpart:
                            foundUri.substring(0, delimiterPosition) || foundUri,
                    });
                } else if (originator === "remote") {
                    if (!canAcceptIncomming) {
                        this.logger.debug('incoming call replied with 486 "Busy Here", cant accept calls right now');
                        rtcSession.terminate({
                            status_code: 486,
                            reason_phrase: "Not accepting calls",
                        });
                        return;
                    }
                    const foundUri = rtcRequest.from.toString();
                    const foundLocalUri = rtcRequest.to.toString();
                    const delimiterLocalPosition = foundUri.indexOf(";") || null;
                    const numberOrUri = foundLocalUri.substring(0, delimiterLocalPosition) || foundLocalUri;
                    const delimiterPosition = foundUri.indexOf(";") || null;
                    this.setState({
                        callDirection: CALL_DIRECTION_INCOMING,
                        callStatus: CALL_STATUS_STARTING,
                        callCounterpart:
                            foundUri.substring(0, delimiterPosition) || foundUri,
                    });
                    let number = "";
                    let tmp = foundUri.substring(0, delimiterPosition) || foundUri;
                    let buff = "";
                    let read = false;
                    for (let chr of tmp.split("")) {
                        if (chr === "<") {
                            read = true;
                            continue;
                        } else if (chr === ">") {
                            read = false;
                            continue;
                        };
                        if (read === true) buff += chr;
                    };
                    number = buff.split("@")[0].replace("sip:", "");

                    let isBlocked = await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/blockedNumbers/checkIfNumberBlocked`,
                        data: {
                            Number: number,
                            Type: 1
                        },
                        ...backendModule.axiosConfig
                    }).then(res => res.data).catch(() => backendModule.genericError);
                    if (isBlocked.status === "ok") {
                        if (isBlocked.data) {
                            this.logger.debug(`incoming call replied with 608 "Rejected", phone number blocked: ${number}`);
                            rtcSession.terminate({
                                status_code: 608,
                                reason_phrase: "Number blocked",
                            });
                            return;
                        };
                    };
                    this.props.dispatch(modalActions.addModal(<SIPCallModal type="incomming" phone={number} localPhone={numberOrUri.split(":")[1].split("@")[0]} />));
                }
                const { rtcSession: rtcSessionInState } = this.state;

                // Avoid if busy or other incoming
                if (rtcSessionInState) {
                    this.logger.debug('incoming call replied with 486 "Busy Here"');
                    rtcSession.terminate({
                        status_code: 486,
                        reason_phrase: "Busy Here",
                    });
                    return;
                }
                fireUAEvent("newRTCSession", { originator, rtcSession, rtcRequest });
                if (rtcSession?.sendDTMF) {
                    sendDTMF = (time, duration) => {
                        rtcSession.sendDTMF(time, { duration: duration, interToneGap: 300 });
                    };
                };
                hangupCall = () => {
                    rtcSession.terminate();
                };
                this.setState({ rtcSession });
                rtcSession.on("failed", (e) => {
                    fireRTCEvent("failed", e);
                    sendDTMF = (time, duration) => { };
                    if (this.ua !== ua) {
                        return;
                    }

                    this.setState({
                        rtcSession: null,
                        callStatus: CALL_STATUS_IDLE,
                        callDirection: null,
                        callCounterpart: null,
                    });
                });
                rtcSession.on("ended", (e) => {
                    fireRTCEvent("ended", e);
                    sendDTMF = (time, duration) => { };
                    if (this.ua !== ua) {
                        return;
                    }

                    this.setState({
                        rtcSession: null,
                        callStatus: CALL_STATUS_IDLE,
                        callDirection: null,
                        callCounterpart: null,
                    });
                });

                rtcSession.on("accepted", () => {
                    fireRTCEvent("accepted", null);
                    if (this.ua !== ua) {
                        return;
                    }

                    [
                        this.remoteAudio.srcObject,
                    ] = rtcSession.connection.getRemoteStreams();
                    // const played = this.remoteAudio.play();
                    const played = this.remoteAudio.play();

                    if (typeof played !== "undefined") {
                        played
                            .catch(() => {
                                /**/
                            })
                            .then(() => {
                                setTimeout(() => {
                                    this.remoteAudio.play();
                                }, 2000);
                            });
                        this.setState({ callStatus: CALL_STATUS_ACTIVE });
                        return;
                    }

                    setTimeout(() => {
                        this.remoteAudio.play();
                    }, 2000);

                    this.setState({ callStatus: CALL_STATUS_ACTIVE });
                });

                if (
                    this.state.callDirection === CALL_DIRECTION_INCOMING &&
                    this.props.autoAnswer
                ) {
                    this.logger.log("Answer auto ON");
                    this.answerCall();
                } else if (
                    this.state.callDirection === CALL_DIRECTION_INCOMING &&
                    !this.props.autoAnswer
                ) {
                    this.logger.log("Answer auto OFF");
                } else if (this.state.callDirection === CALL_DIRECTION_OUTGOING) {
                    this.logger.log("OUTGOING call");
                }
            },
        );

        const extraHeadersRegister = this.props.extraHeaders.register || [];
        if (extraHeadersRegister.length) {
            ua.registrator().setExtraHeaders(extraHeadersRegister);
        }
        ua.start();
    }

    render() {
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                key: "mainSIPProvider-parser",
                sip: {
                    ...this.props,
                    status: this.state.sipStatus,
                    errorType: this.state.sipErrorType,
                    errorMessage: this.state.sipErrorMessage,
                },
                call: {
                    id: this.props.id ?? null,
                    status: this.state.callStatus,
                    direction: this.state.callDirection,
                    counterpart: this.state.callCounterpart,
                },
                ua: this.ua,
                registerSip: this.registerSip,
                unregisterSip: this.unregisterSip,

                answerCall: this.answerCall,
                startCall: this.startCall,
                stopCall: this.stopCall
            });
        });
    }
}

const SIPParser = (props) => {
    const sipDispatch = useDispatch();

    React.useEffect(() => {
        sipDispatch(sipActions.sipUpdate(props));
    }, [props?.call?.status, props?.call?.direction, props?.sip?.status]);

    return null
};

const SIPWrapper = (props) => {
    const [sipData, setSipData] = React.useState(null);

    const sipDispatch = useDispatch();

    React.useEffect(() => {
        if (props.sip?.status !== "ok") return;
        if (!props.sip?.data.Domain) return;
        if (sipData) return;

        setSipData({
            host: props.sip.data.ProxyHost,
            proxyHost: props.sip.data.Domain,
            port: 7443,
            user: props.sip.data.Username,
            password: props.sip.data.Password,
            autoRegister: true,
            key: Date.now(),
            iceServers: [
                {
                    urls: "stun:backend.scale-crm.com:3478",
                    username: "scalecrm",
                    credential: "PByz9Smxvc"
                },
                {
                    urls: "turn:backend.scale-crm.com:3478",
                    username: "scalecrm",
                    credential: "PByz9Smxvc"
                }
            ]
        });
    }, [props.sip, sipData]);

    if (!sipData) return null;
    return <SipProvider {...sipData} dispatch={sipDispatch} key="mainSIPProvider-provider">
        <SIPParser key="mainSIPProvider-parser" />
    </SipProvider>;
};

export {
    SipProvider,
    SIPWrapper,
    registerSip,
    unregisterSip,
    startCall,
    stopCall,
    hangupCall,
    answerCall,
    sendDTMF,

    rtcOn,
    rtcOff,

    uaOn,
    uaOff,

    setAcceptIncomming
}