import React from "react";
import { HashRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { SIPWrapper } from './components/SIPJS';
import AnimateModule from './modules/animateModule'
import { closeAllModals } from "./modules/componentAnimation";

import ContentWrapper from "./components/ContentWrapper";
import Spinner from "./components/customComponents/Spinner";

import Modals_ViewLeads from "./routes/modals/ViewLeads";
import Modals_ViewLeadsByClientID from "./routes/modals/ViewLeadsByClientID";
import GenerateLabelsDaily from "./routes/ShippmentAgent/GenerateLabels/generateLabelsAutomatic";
import GenerateLabelsX from "./routes/ShippmentAgent/GenerateLabels/generateLabelsX";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import * as userActions from './actions/userActions';
import * as statusCodesActions from './actions/statusCodesAction';
import * as notificationActions from "./actions/notificationActions";
import * as refreshActions from "./actions/refreshAction";
import * as backendTasksActions from "./actions/backendTasksAction";
import * as sipActions from "./actions/sipActions";
import * as backendModule from './modules/backendModule';
import * as socketModule from "./modules/socketModule";
import * as wakeupWorker from "./modules/wakeupWorker";
import * as inactiveAgentsModule from "./modules/inactiveAgentsModule";
import { animateBox } from "./modules/componentAnimation";
import { Tasks_singleTask } from "./components/Tasks";
import './breakModal.scss';
import { updateActivity } from "./actions/activityAction";

import FirstTimeGreetings from "./components/FirstTimeGreetings";
import translate from './translate.json';

// components
const LandingPage = React.lazy(() => import("./routes/LandingPage"));
const LandingPageNoLogin = React.lazy(() => import("./routes/LandingPageNoLogin"));
const CRM = React.lazy(() => import("./routes/CRM"));
const WeeklyReportPub = React.lazy(() => import("./routes/Statistics/WeeklyReportPubs"));
const ShipmentAgentReport = React.lazy(() => import("./routes/Statistics/ShipmentAgentsReport"));
const ShippmentReport = React.lazy(() => import("./routes/Statistics/WeeklyShipmentReport"));
const ShippmentReportFinance = React.lazy(() => import("./routes/Statistics/DeliveryReportFinance"));
const ArticlesPrint = React.lazy(() => import("./routes/AdminPanel/Articles/ArticlesPrint"));
const SaveShipments = React.lazy(() => import("./routes/ShippmentAgent/SaveShipments"));
const CreateShipment = React.lazy(() => import("./routes/ShippmentAgent/CreateShipment"));
const GenerateLabels = React.lazy(() => import("./routes/ShippmentAgent/GenerateLabels"));
const PrepareShippment = React.lazy(() => import("./routes/ShippmentAgent/PrepareShippment"));
const ProcessDeals = React.lazy(() => import("./routes/ShippmentAgent/ProcessDeals"));
const ShippmentAgent = React.lazy(() => import("./routes/ShippmentAgent"));
const Reclamations = React.lazy(() => import("./routes/Reclamations"));
const CCManagerAgents = React.lazy(() => import("./routes/CCManager/ActiveAgents"));
const Marketing = React.lazy(() => import("./routes/Marketing"));
const Profile = React.lazy(() => import("./routes/Profile"));
const Login = React.lazy(() => import("./routes/Login"));
const UserRecurring = React.lazy(() => import("./routes/UserRecurring"));
const CallControl = React.lazy(() => import("./routes/CallControl"));

const App = () => {

  const pageTitle = React.useRef("Scale CRM")

  const timestamp = useSelector((state) => state.timeStamp);
  const userData = useSelector((state) => state.userData);
  const activitySelector = useSelector(state => state.activity?.status);
  const statusCodesSelector = useSelector(state => state.statusCodes);
  const chatRoomsSelector = useSelector(state => state.chatRooms ?? []);

  const curTimeoutRef = React.useRef();

  const [SIPData, setSIPData] = React.useState(null);
  const [internalSpinner, setInternalSpinner] = React.useState(false);
  const [firstLogin, setFirstLogin] = React.useState(false);

  let userDataDispatch = useDispatch();
  const navigate = useNavigate();

  const animateNavigate = to => {
    AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
  };

  const getSIPInfo = () => {
    if (SIPData) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getSipDetails`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setSIPData(res.data);
      } else {
        setSIPData({ status: "error", data: "SERVER_ERROR" });
      };
    }).catch(() => {
      setSIPData({ status: "error", data: "SERVER_ERROR" });
    });
  };


  const checkLogin = () => {
    clearTimeout(curTimeoutRef.current);
    return new Promise(resolve => {
      socketModule.internal__checkAgentActivity(userData?.userData?.UserInfo?.ID, backendModule.getStore()?.getState()?.activity?.status ?? "online");
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/auth/checkLogin`,
        data: {
          BoundUser: userData.userData?.UserInfo?.ID,
          State: backendModule.getStore()?.getState()?.activity?.status ?? "online"
        },
        ...backendModule.axiosConfig
      }).then((e) => {
        setInternalSpinner(false);
        if (e.data.status === "ok") {
          if (!socketModule.getSocket()) socketModule.init();
          if (!firstLogin) setFirstLogin(true);
          if (!userData?.isLoggedIn) {
            userDataDispatch(userActions.setUserData(e.data.data));
            userDataDispatch(userActions.setLoginStatus(true));
            if (e.data.data.UserInfo.UserBackground === 'https://wallpaperaccess.com/full/1512558.jpg') {
              console.log(1)
              document.querySelector('#rootImg').style.backgroundImage = `${e.data.data.UserInfo.UserBackground}`
            } else {
              console.log(2)
              document.querySelector('#rootImg').style.backgroundImage = `url(/images/${e.data.data.UserInfo.UserBackground}.png)`
            }
            if (window.location.href.includes('login')) {
              animateNavigate('/')
            };
          } else {
            userDataDispatch(userActions.setUserData(e.data.data));
          };
        } else {
          if (userData?.isLoggedIn !== false) {
            userDataDispatch(userActions.setUserData(null));
            userDataDispatch(userActions.setLoginStatus(false));
            closeAllModals();
            if (firstLogin) setFirstLogin(false);
          };
          socketModule.disconnect();
        };
        resolve(20000);
      }).catch(() => {
        if (userData?.isLoggedIn !== false) {
          try {
            socketModule.disconnect();
          } catch { };
          setInternalSpinner(true);
        };
        resolve(2000);
      });
    });
  }
  const getStatusCodes = () => {
    if (Object.keys(statusCodesSelector?.leads ?? {}).length > 0) return;
    axios({
      method: 'POST',
      url: `${backendModule.backendURL}/common/getStatusCodes`,
      data: {
      },
      ...backendModule.axiosConfig
    }).then(res => {
      userDataDispatch(statusCodesActions.updateCodes(res.data))
    })
  }

  React.useEffect(() => {
    let checkLoginRef = (dur) => {
      clearTimeout(curTimeoutRef.current);
      if (!dur) {
        checkLogin().then(checkLoginRef);
      } else {
        curTimeoutRef.current = setTimeout(() => {
          checkLogin().then(checkLoginRef);
        }, dur);
      };
    };
    checkLoginRef();

    return () => clearTimeout(curTimeoutRef.current);
  }, [userData?.isLoggedIn, timestamp, activitySelector]);

  React.useEffect(() => {
    const handler = task => {
      task = backendModule.parseData(task);
      userDataDispatch(notificationActions.addNotification({
        text: `[Podsjetnik zadatka] ${task.Name}`,
        confirmText: "Pogledaj zadatak",
        closeText: "Podsjeti me za 30min",
        onConfirm: (e, notifyData) => {
          animateBox({ currentTarget: e.currentTarget.parentNode.parentNode.parentNode }, <Tasks_singleTask data={task} onFinish={() => {
            userDataDispatch(notificationActions.closeNotification(notifyData.ID));
            socketModule.internal__taskReminderReceived(task.ID);
          }} />);
        }
      }));
    };

    if (userData?.isLoggedIn !== true) return socketModule.off("pingTaskReminder", handler);

    socketModule.on("pingTaskReminder", handler);

    let wakeupTimeout = null;
    if (userData?.isLoggedIn) {
      wakeupWorker.startWorker(() => {
        socketModule.disconnect();
        clearTimeout(wakeupTimeout);
        wakeupTimeout = setTimeout(() => {
          userDataDispatch(backendTasksActions.updateBackendTasks([]));
          socketModule.init();
        }, 500);
      });
    };

    return () => {
      socketModule.off("pingTaskReminder", handler);
      wakeupWorker.stopWorker();
      clearTimeout(wakeupTimeout);
    };
  }, [userData?.isLoggedIn]);

  React.useEffect(() => {
    const handler = notification => {
      userDataDispatch(notificationActions.addNotification({
        text: `[Admin] ${notification}`,
        closeText: "Obriši",
        onClose: (e, notifyData) => {
          userDataDispatch(notificationActions.closeNotification(notifyData.ID));
        }
      }));
    };

    if (userData?.isLoggedIn !== true) return socketModule.off("receiveGlobalNotification", handler);
    socketModule.on("receiveGlobalNotification", handler);

    return () => {
      socketModule.off("receiveGlobalNotification", handler);
    };
  }, [userData?.isLoggedIn]);

  React.useEffect(() => {
    const handler = refresh => {
      userDataDispatch(refreshActions.addRefresh({
      }));
    };

    if (userData?.isLoggedIn !== true) return socketModule.off("receiveFrontendRefresh", handler);
    socketModule.on("receiveFrontendRefresh", handler);

    return () => {
      socketModule.off("receiveFrontendRefresh", handler);
    };
  }, [userData?.isLoggedIn]);



  React.useEffect(() => {
    if (userData?.isLoggedIn) {
      if (!SIPData) getSIPInfo();
      getStatusCodes();
    } else {
      if (SIPData) setSIPData(null);
    };
  }, [userData?.isLoggedIn, SIPData]);

  React.useEffect(() => {
    const unreadNotifications = chatRoomsSelector.reduce((acc, val) => acc + val.UnreadCount, 0);
    if (unreadNotifications > 0) {
      document.title = `(${unreadNotifications}) ${pageTitle.current}`;
    } else {
      document.title = pageTitle.current;
    };
  }, [chatRoomsSelector]);

  React.useEffect(() => {
    let isInit = false;

    if (!userData) return;
    if (!userData.isLoggedIn) return;
    if (!userData?.userData?.UserInfo?.Flags?.isCCManager) return;
    if (!userData?.userData?.UserInfo?.Flags?.CCManager_inactiveAgentNotification) return;

    isInit = true;
    inactiveAgentsModule.init();


    return () => {
      if (isInit) inactiveAgentsModule.close();
    };
  }, [userData]);

  if (window.location.hash.startsWith("#/modals") && userData.isLoggedIn) return <Routes>
    <Route path="/modals/viewLeads" element={<Modals_ViewLeads />} />
    <Route path="/modals/viewLeadsByClientID" element={<Modals_ViewLeadsByClientID />} />
  </Routes>
  if (window.location.hash.startsWith("#/ShippmentAgent/GenerateLabelsDaily") && userData.isLoggedIn) return <Routes>
    <Route path="/ShippmentAgent/GenerateLabelsDaily" element={<GenerateLabelsDaily />} />
  </Routes>
  if (window.location.hash.startsWith("#/ShippmentAgent/GenerateLabelsX") && userData.isLoggedIn) return <Routes>
    <Route path="/ShippmentAgent/GenerateLabelsX" element={<GenerateLabelsX />} />
  </Routes>
  if (window.location.hash.startsWith("#/ShippmentAgent/GenerateLabels")) return <Routes>
    <Route path="/ShippmentAgent/GenerateLabels" element={<GenerateLabels />} />
  </Routes>
  if (window.location.hash.startsWith("#/ArticlesPrint")) return <Routes>
    <Route path="/ArticlesPrint" element={<ArticlesPrint />} />
  </Routes>

  if (userData?.isLoggedIn === null || userData?.isLoggedIn === undefined) return <Spinner align="center" />
  return <React.Fragment key="mainWindow">
    <div className="root__internalSpinner" style={{
      opacity: internalSpinner ? 1 : 0,
      pointerEvents: internalSpinner ? "all" : "none"
    }} onClick={e => e.stopPropagation()}>
      <Spinner align="center" color="#6664E5" />
      <p>Povezivanje na server...</p>
    </div>
    <SIPWrapper key="mainSIPProvider" sip={SIPData} />
    <Break />
    <SIPAgentStatusCheck />
    {/* {(userData.isLoggedIn && !userData.userData?.UserInfo?.Flags?.isAdmin) && <FirstTimeGreetings />} */}
    <ContentWrapper className={`${!firstLogin ? "content-noLogin" : ""}`}>
      <React.Suspense fallback={<Spinner />}>

        {firstLogin ? <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/crm" element={<CRM />} />
          <Route path="/weeklyReport" element={<WeeklyReportPub />} />
          <Route path="/weeklyReportReccuring" element={<WeeklyReportPub isRec={true} />} />
          <Route path="/shipmentAgentReport" element={<ShipmentAgentReport />} />
          <Route path="/shipmentReport" element={<ShippmentReport />} />
          <Route path="/shipmentReportF" element={<ShippmentReportFinance />} />
          <Route path="/callControl" element={<CallControl />} />
          <Route path='/profile' element={<Profile />} />
          <Route path="/ShippmentAgent/ProcessDeals" element={<ProcessDeals />} />
          <Route path="/ShippmentAgent/PrepareShippment" element={<PrepareShippment />} />
          <Route path="/ShippmentAgent/CreateShipment" element={<CreateShipment />} />
          <Route path="/ShippmentAgent/SaveShipments" element={<SaveShipments />} />
          <Route path="/ShippmentAgent" element={<ShippmentAgent />} />
          <Route path="/activeAgents" element={<CCManagerAgents />} />
          <Route path="/marketing" element={<Marketing />} />
          <Route path="/reclamations" element={<Reclamations />} />
        </Routes> : <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/recurring" element={<UserRecurring />} />
          <Route path="*" element={<LandingPageNoLogin />} />
        </Routes>}

      </React.Suspense>
    </ContentWrapper>
  </React.Fragment>
};

const Break = () => {
  let userDispatch = useDispatch();
  let userData = useSelector((state) => state.userData.userData);
  let language = useSelector(state => state.language);

  React.useEffect(() => {
    if (userData?.BreakInfo?.onBreak) userDispatch(updateActivity("Na pauzi"));
  }, [userData?.BreakInfo?.onBreak]);

  return <div className={userData?.BreakInfo?.onBreak === true ? "component__breakModal" : ' component__breakModal component__breakModal-hidden'}>
    <img alt='' src={userData?.UserInfo?.UserImage !== null ? userData?.UserInfo?.UserImage : '/images/udefined.png'}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/images/undefined.png";
      }}
    />
    <p>{userData?.UserInfo?.FirstName} {userData?.UserInfo?.LastName}</p>
    <p>{translate[language].PreostaloVrijemeZaPauzu}: {(userData?.BreakInfo?.totalTime / 60).toFixed(0)} {translate[language].Minuta}</p>
    <button
      onClick={() => {
        axios({
          method: 'POST',
          url: `${backendModule.backendURL}/users/setOnBreak`,
          data: {
            isOnBreak: false
          },
          ...backendModule.axiosConfig
        }).then(() => null).catch(() => null);
      }}
    >{translate[language].ZavrsiPauzu}</button>
  </div>
};

let agentInterval = null;
const SIPAgentStatusCheck = () => {
  const sipSelector = useSelector(state => state?.sip ?? null);
  const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

  const mainDispatch = useDispatch();

  const updateAgentStatus = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/setSIPIncommingLoggedStatus`,
      data: {
        Status: true
      },
      ...backendModule.axiosConfig
    }).then(() => {
      checkAgentStatus(true);
    }).catch(() => null);
  };

  const checkAgentStatus = (justCheck = false) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getSIPIncommingLoggedStatus`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (res.data.data === false && !justCheck) {
          updateAgentStatus();
        };
        mainDispatch(sipActions.setInboundStatus(res.data.data));
      };

    }).catch(() => null);
  };

  const notIncommingAgent = () => {
    mainDispatch(sipActions.setInboundStatus(false))
  };

  React.useEffect(() => {
    if (!sipSelector?.call?.status) return;
    if (!userInfoSelector?.Flags) return;

    if (!userInfoSelector?.Flags?.isShippmentAgent) return notIncommingAgent();
    if (userInfoSelector?.Flags?.disallowInboundCalls) return notIncommingAgent();

    clearInterval(agentInterval);
    agentInterval = setInterval(() => {
      checkAgentStatus();
    }, 20000);

    checkAgentStatus();

    return () => clearInterval(agentInterval);
  }, [sipSelector?.call?.status, userInfoSelector]);

  return null;
};

export default App;
