import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { getDealShipColor } from "../../modules/leadModule";
import { animateBox } from "../../modules/componentAnimation";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import { FilteredCustomTable } from "../customComponents/Table";
import Dropdown from "../customComponents/Dropdown";
import Spinner from "../customComponents/Spinner";

const KanbanStatistics = (props) => {
    const [data, setData] = React.useState();
    const getData = () => {
        let url;
        if (props.type === 'deal') {
            url = `${backendModule.backendURL}/deals/getAllDealsDashboardStatistics`
        } else {
            url = `${backendModule.backendURL}/leads/getAllLeadsDashboardStatistics`
        }
        axios({
            method: "POST",
            url: url,
            data: {
                filters: props.filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data)
            } else {
                return setData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR" });
        });
    };
    React.useEffect(() => {
        getData();
    }, [])


    const formatirajBroj = (broj) => {
        return new Intl.NumberFormat('bs-BA', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(broj);
    };





    return <div className="component__kanbanStatistics">
        <p className="component__kanbanStatistics__close" onClick={props.onClose}>&times;</p>
        {data ? <>
            {data?.status === "ok" ? <>
                {props.type === "deal" ? <div className="component__kanbanStatistics__deal">
                    <div>
                        <p>Ukupno dealova</p>
                        <p>{data.data.count}</p>
                    </div>
                    <div>
                        <p>Dostavljeno dealova</p>
                        <p>{data.data.deliveredDeals}</p>
                    </div>
                    <div>
                        <p>Dealova u slanju</p>
                        <p>{data.data.sendingDeals}</p>
                    </div>
                    <div>
                        <p>DR</p>
                        <p>{Number(data.data.deliveredDeals / data.data.count * 100).toFixed(2)} %</p>
                    </div>
                    <div>
                        <p>Revenue</p>
                        <p>{formatirajBroj(Number(data.data.amountTotal))} €</p>
                    </div>
                    <div>
                        <p>Average Revenue</p>
                        <p>{formatirajBroj(Number(data.data.amountTotal) / data.data.count)} €</p>
                    </div>
                    <div>
                        <p>Revenue delivered</p>
                        <p>{formatirajBroj(Number(data.data.amountDelivered))} €</p>
                    </div>
                    <div>
                        <p>Revenue in delivery</p>
                        <p>{formatirajBroj(Number(data.data.amountInDelivery))} €</p>
                    </div>
                </div> : <div className="component__kanbanStatistics__lead">
                    <div>
                        <p>Ukupno leadova</p>
                        <p>{data.data.count}</p>
                    </div>
                    <div>
                        <p>Potvdjeno leadova</p>
                        <p>{data.data.approvedCount}</p>
                    </div>
                    <div>
                        <p>AR</p>
                        <p>{Number(data.data.approvedCount / data.data.count * 100).toFixed(2)} %</p>
                    </div>
                    <div>
                        <p>Revenue</p>
                        <p>{formatirajBroj(Number(data.data.Revenue))} €</p>
                    </div>
                    <div>
                        <p>Average Revenue</p>
                        <p>{formatirajBroj(Number(data.data.Revenue) / data.data.approvedCount)} €</p>
                    </div>
                    <div></div>
                </div>}
            </> : <p>Doslo je do greske prilikom dohvatanja podataka</p>}

        </> : <Spinner />}
    </div>
};



export default KanbanStatistics;