import React from "react";
import "./index.scss";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { useSelector } from "react-redux";
import { off, on } from '../../../modules/socketModule'
import { getDealShipColor, getDealSaveShipColor, getUnpaidRemindersStatusColor, getRecurringPaymentColor } from "../../../modules/leadModule";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";
import moment from "moment";
import publishers from "../../../routes/Statistics/WeeklyReportPubs/publishers.json";
import KanbanStatistics from "../../DealLeadKanbanStatistics";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from "react-date-range";

import AudioPlayer from "../AudioPlayer";
import FilterPanel from "../FilterPanel";
import CustomButtonSmall from "../Button";
import Spinner from "../Spinner";

import EditLead from "../../EditLead";
import EditDeal from "../../EditDeal";
import DealsList from "../../../routes/ShippmentAgent/DealsList";
import DealShipmentHistory from "../../DealShipmentHistory";
import SingleLead from "../../SingleLead";
import Dropdown from "../Dropdown";

import ExportCSV from "./exportCSV";
import translate from "./translate.json";
import filtersTranslate from '../../filterstranslate.json';

const Kanban = (props) => {
    const statusCodes = useSelector((state) => state.statusCodes ?? {});
    const userFlagsSelector = useSelector(state => state?.userData?.userData?.UserInfo?.Flags ?? {});
    const dealSaveStatusCodes = useSelector(state => state?.statusCodes?.dealSaves ?? null);
    const leadStatusSelector = useSelector(state => state?.statusCodes?.leads ?? {});
    const dealStatusSelector = useSelector(state => state?.statusCodes?.deals ?? {});

    const chatUsers = useSelector((state) => state.chatUsers);

    const [filters, setFilters] = React.useState([])
    const [dateFilters, setDateFilters] = React.useState([]);
    const [parsedFilters, setParsedFilters] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [leadTypes, setLeadTypes] = React.useState([]);
    const [itemCount, setItemCount] = React.useState({});
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [contextMenu, setContextMenu] = React.useState({ top: 0, left: 0, visible: false });

    let language = useSelector(state => state.language);
    const illnessesSelector = useSelector(state => state?.statusCodes?.illnesses ?? {});
    const curDefer = useDefer();

    const getItemCounts = () => {
        let urlEnd = "/leads/getLeadCount";
        let subObject = props?.type === "deal" ? "deals" : "leads";
        if (props.type === "deal") urlEnd = "/deals/getDealCount";

        for (let item of Object.keys(statusCodes[subObject])) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}${urlEnd}`,
                data: {
                    Status: item,
                    filters: [
                        ...filters,
                        selectedCountry ? {
                            name: "Country",
                            op: "eq",
                            value: selectedCountry
                        } : null,
                        ...(props.customFilters ? props.customFilters : []),
                        ...dateFilters
                    ].filter(t => t)
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setItemCount(d => {
                        return {
                            ...d,
                            [item]: res.data.data
                        };
                    });
                } else {
                    setItemCount(d => {
                        return {
                            ...d,
                            [item]: "?"
                        };
                    });
                };
            }).catch(() => {
                setItemCount(d => {
                    return {
                        ...d,
                        [item]: "?"
                    };
                });
            }).finally(() => {
                setParsedFilters([
                    ...filters,
                    selectedCountry ? {
                        name: "Country",
                        op: "eq",
                        value: selectedCountry
                    } : null,
                    ...(props.customFilters ? props.customFilters : [])
                ].filter(t => t));
            });
        };
    };

    React.useEffect(() => {
        const handler = () => {
            if (contextMenu.visible) setContextMenu(cm => {
                return { ...cm, visible: false };
            });
        };
        window.addEventListener("click", handler);

        return () => window.removeEventListener("click", handler);
    }, [contextMenu]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                let tmp = [{ name: translate[language].Svedržave, value: null }]
                res.data.data?.map(country => {
                    tmp.push({ name: country.CountryName, value: country.CountryCode })
                })
                return setCountries(tmp);
            }
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        }).catch(() => {
            return setCountries({ status: "error", data: "SERVER_ERROR" });
        });
    }, []);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/customTypes/getAllFilters`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                return setLeadTypes(res.data.data);
            };
            return setLeadTypes([]);
        }).catch(() => {
            return setLeadTypes([]);
        });
    }, []);

    React.useEffect(() => {
        curDefer(getItemCounts, 0);

        let handler = () => {
            curDefer(getItemCounts, 10000);
        };

        let handlerName = props.type === "deal" ? "pingDealChanges" : "pingLeadChanges";

        if (!(props?.customFilters?.length > 0)) {
            on(handlerName, handler);
        };

        return () => off(handlerName, handler);
    }, [filters, dateFilters, selectedCountry]);
    const translateStatusCodesDeals = (status) => {
        if (language === "BA" || language === "SI") {
            return status
        } else {
            return {
                "Nova prilika": "Oportunitate nouă",
                "Obrađeno": "Procesat",
                "Priprema za isporuku": "Pregătire pentru livrare",
                "Poslano": "Trimis",
                "Paket zamijenjen": "Pachet înlocuit",
                "Poništeni paket": "Pachet anulat",
                "Deal izgubljen": "Tranzacție pierdută",
                "Problemi sa isporukom": "Probleme la livrare",
                "Isporučeno": "Livrare efectuată",
                "Odgođeno slanje": "Trimitere amânată"
            }[status]
        }

    }
    const translateStatusCodesLeads = (status) => {
        if (language === "BA" || language === "SI") {
            return status
        } else if (language === "RO") {
            return {
                "Obrada": "Procesare",
                "Pozvati ponovo": "Reapelati",
                "Loš lead": "Prospect slab",
                "Odbijen lead": "Prospect respins",
                "Dobar lead": "Prospect bun",
                "Zadnja šansa": "Ultima șansă",
                "Novi upit": "Cerere nouă"
            }[status]
        }

    }

    return <>
        {(userFlagsSelector?.isAdmin || (userFlagsSelector?.isCCManager && userFlagsSelector?.CCManager_allowLeadDealExport)) && <div className={`component__kanban__context ${contextMenu.visible ? "component__kanban__context--active" : ""}`} style={{
            top: `${contextMenu.top}px`,
            left: `${contextMenu.left}px`
        }} onClick={(e) => {
            e.stopPropagation();
        }}>
            <p onClick={e => animateBox(e, <ExportCSV type={props.type === "deal" ? "deal" : "lead"} filters={parsedFilters} reject={false} />)}>Export u CSV</p>
            {props.type !== "deal" && <p onClick={e => animateBox(e, <ExportCSV type={props.type === "deal" ? "deal" : "lead"} filters={parsedFilters} reject={true} />)}>Exportuj razloge odbijanja</p>}
        </div>}
        <div className="component__kanban__filters" style={{
            width: "100%",
            height: "auto",
            display: "grid",
            gridTemplateRows: "auto",
            gridTemplateColumns: `${(userFlagsSelector?.isAdmin || (userFlagsSelector?.isCCManager && userFlagsSelector?.CCManager_allowLeadDealExport)) ? "1fr 50px auto auto 200px" : "1fr auto auto 200px"}`,
            columnGap: "10px",
            alignItems: "center",
            backgroundColor: "rgb(39, 39, 55)"
        }}>
            <FilterPanel
                style={{ backgroundColor: "transparent" }}
                accent="rgb(103, 103, 128)"
                theme="dark"
                filterCB={fi => setFilters(fi)}
                filters={props.type === 'deal' ? [
                    { name: "ID", friendlyName: filtersTranslate[language]["ID"], type: "string" },
                    { name: "LeadID", friendlyName: "Lead ID", type: "string" },
                    { name: "ClientID", friendlyName: "Client ID", type: "string" },
                    { name: "TrackingCodeInternal", friendlyName: filtersTranslate[language].Internikodzapraćenje, type: "string" },
                    { name: "TrackingCodeExternal", friendlyName: filtersTranslate[language].Eksternikodzapraćenje, type: "string" },
                    { name: "Name", friendlyName: filtersTranslate[language].Nazivdeala, type: "string" },
                    {
                        name: "Status", friendlyName: filtersTranslate[language].Statusdeala, type: "custom", varType: "number", data: Object.keys(dealStatusSelector).map(dealStatusKey => {
                            return { text: dealStatusSelector[dealStatusKey], value: dealStatusKey }
                        })
                    },
                    { name: "Amount", friendlyName: filtersTranslate[language]["Cijena"], type: "number" },
                    { name: "FirstLastName", friendlyName: filtersTranslate[language]["Ime i prezime kupca"], type: "string" },
                    { name: "PhoneNumber", friendlyName: filtersTranslate[language]["Broj telefona kupca"], type: "string" },
                    { name: "Address", friendlyName: filtersTranslate[language]["Adresa kupca"], type: "string" },
                    { name: "City", friendlyName: filtersTranslate[language]["Grad kupca"], type: "string" },
                    {
                        name: "Country", friendlyName: filtersTranslate[language]["Država kupca"], type: "custom", varType: "string", data: countries.filter(t => t.value !== null).map(t => {
                            return { text: t.name, value: t.value }
                        })
                    },
                    {
                        name: "ResponsiblePerson", friendlyName: filtersTranslate[language]["Odgovorni agent"], type: "custom", varType: "number", data: chatUsers.filter(t => t.value !== null).map(t => {
                            return { text: t.FirstName + ' ' + t.LastName, value: t.ID }
                        })
                    },
                    { name: "ResponsiblePerson", friendlyName: "Odgovorni agent ID", type: "string" },
                    {
                        name: "DealResponsiblePerson", friendlyName: filtersTranslate[language].Odgovorniagentzadeal, type: "custom", varType: "number", data: chatUsers.filter(t => t.value !== null).map(t => {
                            return { text: t.FirstName + ' ' + t.LastName, value: t.ID }
                        })
                    },
                    { name: "HouseNumber", friendlyName: filtersTranslate[language]["Broj kuće kupca"], type: "string" },
                    {
                        name: "DealSaveStatus", friendlyName: filtersTranslate[language].Statusproblematike, type: "custom", varType: "number", data: Object.keys(dealSaveStatusCodes).map(item => {
                            return { text: dealSaveStatusCodes[item], value: item }
                        })
                    },
                    { name: "isRecurring", friendlyName: filtersTranslate[language]["Produženje"], type: "boolean" },
                    { name: "isRecurringWithGuarantee", friendlyName: filtersTranslate[language]["Produženje s garancijom"], type: "boolean" },
                    { name: "ScheduledCallTime", friendlyName: filtersTranslate[language]["Datum ponovnog poziva"], type: "date" },
                    { name: "createdAt", friendlyName: filtersTranslate[language]["Datum kreiranja"], type: "date" },
                    { name: "ScheduledDelivery", friendlyName: "Datum odgodjenog slanja", type: "date" },
                    { name: "updatedAt", friendlyName: filtersTranslate[language]["Datum poslednje izmjene"], type: "date" },
                    { name: "agentUpdatedAt", friendlyName: filtersTranslate[language]["Datum posljednje obrade"], type: "date" },
                    { name: "DealShippedAt", friendlyName: "Datum najave", type: "date" },
                    { name: "DealDeliveredAt", friendlyName: "Datum dostave", type: "date" },
                    { name: "CallAttempts", friendlyName: filtersTranslate[language]["Ukupni broj poziva"], type: "number" },
                    { name: "ScheduledCallTimeAttempts", friendlyName: "Broj obrada leada", type: "number" },
                    { name: "isSpam", friendlyName: filtersTranslate[language]["Spam"], type: "boolean" },
                    { name: "isPlarityBrand", friendlyName: filtersTranslate[language]["Plarity brand"], type: "boolean" },
                    { name: "DealMoneyReceived", friendlyName: filtersTranslate[language].Dealrazdužen, type: "boolean" },
                    { name: "isRecurringRejected", friendlyName: filtersTranslate[language].recurringReject, type: "boolean" },
                    {
                        name: "DealRejectReasons", friendlyName: filtersTranslate[language].Razloglošegdeala, type: "custom", data: (statusCodes?.dealSaveRejectReasons ?? []).map(rr => {
                            return { text: rr, value: rr }
                        }), varType: "stringIncludes"
                    },
                    {
                        name: "DealRejectReasons", friendlyName: "Razlog lošeg deala (grupisano)", type: "custom", data: [
                            { text: "Greska poste (kurira) ", value: "Kurir" },
                            { text: "Greska agenta", value: "Greska agenta" },
                            { text: "Kupac", value: "Kupac" }
                        ], varType: "stringIncludes"
                    },
                    { name: "WarningSent", friendlyName: filtersTranslate[language].Opomenaposlana, type: "boolean" },
                    {
                        name: "isRecurringPaymentPaid", friendlyName: filtersTranslate[language].Odgođenoplaćanje, type: "custom", varType: "custom", data: [
                            { text: "Nema", value: null },
                            { text: "1 rata plaćena", value: false },
                            { text: "2 rata plaćena", value: true }
                        ]
                    },
                    {
                        name: "SubType", friendlyName: filtersTranslate[language]["Prilagođeni tip"], type: "custom", varType: "custom", data: [{ Name: "Nema tipa", SubTypeName: null }, ...leadTypes].map(lt => {
                            return { text: lt.Name, value: lt.SubTypeName }
                        })
                    },
                    { name: "PotentialDuplicateDeal", friendlyName: "Potencijalni dupli deal", type: "boolean" },
                    { name: "PotentialWrongProduct", friendlyName: "Potencijalni pogresan proizvod", type: "boolean" },
                    {
                        name: "--Illnesses", friendlyName: filtersTranslate[language].Oboljenje, type: "custom", varType: "stringIncludes", data: Object.keys(illnessesSelector).map(ill => {
                            return { text: illnessesSelector[ill][language] ?? illnessesSelector[ill]["BA"], value: ill }
                        })
                    },
                    { name: "AdditionalData:OriginID", friendlyName: "Scalelead-LeadID", type: "string" },
                    {
                        name: "AdditionalData:PublisherID", friendlyName: "Scalelead-PublisherID", type: "custom", varType: "string", data: publishers.map(pub => {
                            return { text: pub[0], value: pub[1] }
                        })
                    },
                    { name: "AdditionalData:AdvertiserID", friendlyName: "Scalelead-AdvertiserID", type: "string" },
                    { name: "AdditionalData:OfferID", friendlyName: "Scalelead-OfferID", type: "string" },
                    { name: "AdditionalData:TakenOfferID", friendlyName: "Scalelead-TakenOfferID", type: "string" },
                    { name: "AdditionalData:AffiliateSubID", friendlyName: "CRM-AffiliateSubID", type: "string" },
                    { name: "AdditionalData:ClickID", friendlyName: "ScaleTrack-ClickID", type: "string" },
                    { name: "AdditionalData:STCampaignID", friendlyName: "ScaleTrack-CampaignID", type: "string" },
                    {
                        name: "AdditionalData:STIntegrationType", friendlyName: "ScaleTrack-IntegrationType", type: "custom", varType: "number", data: [
                            { text: "Facebook", value: 0 },
                            { text: "Midas", value: 4 },
                            { text: "AdNow", value: 5 },
                            { text: "Tik tok", value: 6 },
                            { text: "Migid", value: 1 },
                            { text: "Social campaings", value: -3 },
                            { text: "Organic", value: -1 },
                            { text: "SMS marketing (SMS, Viber)", value: -2 }
                        ]

                    },
                ] : [
                    { name: "ID", friendlyName: filtersTranslate[language]["ID"], type: "string" },
                    { name: "ObfuscatedID", friendlyName: "Obfuscated ID", type: "string" },
                    { name: "ClientID", friendlyName: "Client ID", type: "string" },
                    { name: "Name", friendlyName: filtersTranslate[language]["Naziv leada"], type: "string" },
                    {
                        name: "Status", friendlyName: filtersTranslate[language]["Status leada"], type: "custom", varType: "number", data: Object.keys(leadStatusSelector).map(leadStatusKey => {
                            return { text: leadStatusSelector[leadStatusKey], value: leadStatusKey }
                        })
                    },
                    { name: "Amount", friendlyName: filtersTranslate[language]["Cijena"], type: "number" },
                    { name: "FirstLastName", friendlyName: filtersTranslate[language]["Ime i prezime kupca"], type: "string" },
                    { name: "PhoneNumber", friendlyName: filtersTranslate[language]["Broj telefona kupca"], type: "string" },
                    { name: "Address", friendlyName: filtersTranslate[language]["Adresa kupca"], type: "string" },
                    { name: "City", friendlyName: filtersTranslate[language]["Grad kupca"], type: "string" },
                    {
                        name: "Country", friendlyName: filtersTranslate[language]["Država kupca"], type: "custom", varType: "string", data: countries.filter(t => t.value !== null).map(t => {
                            return { text: t.name, value: t.value }
                        })
                    },
                    {
                        name: "ResponsiblePerson", friendlyName: filtersTranslate[language]["Odgovorni agent"], type: "custom", varType: "custom", data: [
                            { text: "Nema odgovorne osobe", value: null },
                            ...chatUsers.filter(t => t.value !== null).map(t => {
                                return { text: t.FirstName + ' ' + t.LastName, value: t.ID }
                            })]
                    },
                    { name: "ResponsiblePerson", friendlyName: "Odgovorni agent ID", type: "string" },
                    {
                        name: "ResponsiblePersonBeforeReject", friendlyName: filtersTranslate[language]["Odgovorni agent prije zadnje šanse"], type: "custom", varType: "custom", data: [
                            { text: "Nema odgovorne osobe", value: null },
                            ...chatUsers.filter(t => t.value !== null).map(t => {
                                return { text: t.FirstName + ' ' + t.LastName, value: t.ID }
                            })]
                    },
                    { name: "ResponsiblePersonBeforeReject", friendlyName: "Odgovorni agent prije zadnje šanse ID", type: "string" },

                    { name: "HouseNumber", friendlyName: filtersTranslate[language]["Broj kuće kupca"], type: "string" },
                    { name: "isRecurring", friendlyName: filtersTranslate[language]["Produženje"], type: "boolean" },
                    { name: "isRecurringWithGuarantee", friendlyName: filtersTranslate[language]["Produženje s garancijom"], type: "boolean" },
                    { name: "ScheduledCallTime", friendlyName: filtersTranslate[language]["Datum ponovnog poziva"], type: "date" },
                    { name: "createdAt", friendlyName: filtersTranslate[language]["Datum kreiranja"], type: "date" },
                    { name: "updatedAt", friendlyName: filtersTranslate[language]["Datum poslednje izmjene"], type: "date" },
                    { name: "agentUpdatedAt", friendlyName: filtersTranslate[language]["Datum posljednje obrade"], type: "date" },
                    { name: "CallAttempts", friendlyName: filtersTranslate[language]["Ukupni broj poziva"], type: "number" },
                    { name: "ScheduledCallTimeAttempts", friendlyName: "Broj obrada leada", type: "number" },
                    { name: "isSpam", friendlyName: filtersTranslate[language]["Spam"], type: "boolean" },
                    { name: "isPlarityBrand", friendlyName: filtersTranslate[language]["Plarity brand"], type: "boolean" },
                    {
                        name: "isRecurringPaymentPaid", friendlyName: filtersTranslate[language]["Odgođeno plaćanje"], type: "custom", varType: "custom", data: [
                            { text: "Nema", value: null },
                            { text: "1 rata plaćena", value: false },
                            { text: "2 rata plaćena", value: true }
                        ]
                    },
                    {
                        name: "SubType", friendlyName: filtersTranslate[language]["Prilagođeni tip"], type: "custom", varType: "custom", data: [{ Name: "Nema tipa", SubTypeName: null }, ...leadTypes].map(lt => {
                            return { text: lt.Name, value: lt.SubTypeName }
                        })
                    },
                    {
                        name: "--Illnesses", friendlyName: filtersTranslate[language].Oboljenje, type: "custom", varType: "stringIncludes", data: Object.keys(illnessesSelector).map(ill => {
                            return { text: illnessesSelector[ill][language] ?? illnessesSelector[ill]["BA"], value: ill }
                        })
                    },
                    { name: "AdditionalData:OriginID", friendlyName: "Scalelead-LeadID", type: "string" },
                    {
                        name: "AdditionalData:PublisherID", friendlyName: "Scalelead-PublisherID", type: "custom", varType: "string", data: publishers.map(pub => {
                            return { text: pub[0], value: pub[1] }
                        })
                    },
                    { name: "AdditionalData:AdvertiserID", friendlyName: "Scalelead-AdvertiserID", type: "string" },
                    { name: "AdditionalData:OfferID", friendlyName: "Scalelead-OfferID", type: "string" },
                    { name: "AdditionalData:TakenOfferID", friendlyName: "Scalelead-TakenOfferID", type: "string" },
                    { name: "AdditionalData:AffiliateSubID", friendlyName: "CRM-AffiliateSubID", type: "string" },
                    { name: "AdditionalData:ClickID", friendlyName: "ScaleTrack-ClickID", type: "string" },
                    { name: "AdditionalData:STCampaignID", friendlyName: "ScaleTrack-CampaignID", type: "string" },
                    {
                        name: "AdditionalData:STIntegrationType", friendlyName: "ScaleTrack-IntegrationType", type: "custom", varType: "number", data: [
                            { text: "Facebook", value: 0 },
                            { text: "Midas", value: 4 },
                            { text: "AdNow", value: 5 },
                            { text: "Tik tok", value: 6 },
                            { text: "Migid", value: 1 },
                            { text: "Social campaings", value: -3 },
                            { text: "Organic", value: -1 },
                            { text: "SMS marketing (SMS, Viber)", value: -2 }
                        ]

                    },
                    { name: "AdditionalData:STAngle", friendlyName: "ScaleTrack-Angle", type: "string" },
                    {
                        name: "AdditionalData:CrossSellGenerated", friendlyName: "Is cross sell lead generated", type: "custom", varType: "custom", data: [
                            { text: "Da", value: true },
                            { text: "Ne", value: false }
                        ]
                    }
                ]} />
            {(userFlagsSelector?.isAdmin || (userFlagsSelector?.isCCManager && userFlagsSelector?.CCManager_allowLeadDealExport)) && <CustomButtonSmall accent="#2E2E41" theme="dark" value="..." style={{
                height: "30px",
                width: "50px",
                border: "1px solid #999FAE"
            }} onClick={(e) => {
                e.stopPropagation();
                if (!contextMenu.visible) {
                    setContextMenu({
                        top: e.pageY,
                        left: e.pageX,
                        visible: true
                    });
                } else {
                    setContextMenu(cm => {
                        return { ...cm, visible: false };
                    });
                };
            }} />}
            <div className="customComponents__kanban__statisticsButton" onClick={(e) => {
                animateBox(e, <KanbanStatistics filters={filters} type={props.type} />)
            }}>
                Statistika
            </div>
            <KanbanDate onChange={e => {
                try {
                    if (JSON.stringify(e) === JSON.stringify(dateFilters)) return;
                } catch { };

                if (!e) return setDateFilters([]);

                setDateFilters([
                    { name: e[0].dateType, op: "dgeq", value: e[0].startDate.getTime(), utcOffset: (new Date()).getTimezoneOffset() },
                    { name: e[0].dateType, op: "dleq", value: e[0].endDate.getTime(), utcOffset: (new Date()).getTimezoneOffset() }
                ]);
            }} />
            {countries.length > 0 && <Dropdown theme='dark' accent="#3D3D55" data={countries} style={{
                height: "30px"
            }}
                selected={(() => {
                    let country = countries.find(c => c.value === selectedCountry?.value);
                    if (!country) return 0;
                    return countries.indexOf(country);
                })()}
                onChange={(e) => {
                    setSelectedCountry(e?.value)
                }} />}

        </div>
        <div className="customComponents__kanban">
            {props.type === 'deal' ?
                Object.keys(statusCodes?.deals).map((item, index) => {
                    if (props.onlyShowStatus) {
                        if (!props.onlyShowStatus.includes(Number(item))) return null;
                    };
                    return <div className="customComponents__kanban__leads">
                        <p style={{ zIndex: 2, 'backgroundColor': props?.headersColors[index] }} key={index}>
                            {translateStatusCodesDeals(statusCodes?.deals[item])} ({itemCount[item] ?? <Spinner color="white" style={{ width: "20px", height: "20px" }} />})</p>
                        <div className={`customComponents__kanban__lead  `} >
                            <KanbanStatus agentMode={props.agentMode} onSelect={props.onSelect} country={selectedCountry} customFilters={props.customFilters} status={item} border={props?.headersColors[index]} filters={filters} dateFilters={dateFilters} type={props.type} />
                        </div>
                    </div>
                }) :
                Object.keys(statusCodes?.leads).map((item, index) => {
                    if (props.onlyShowStatus) {
                        if (!props.onlyShowStatus.includes(Number(item))) return null;
                    };
                    return <div className="customComponents__kanban__leads">
                        <p style={{ zIndex: 2, 'backgroundColor': props?.headersColors[index] }} key={index}>
                            {translateStatusCodesLeads(statusCodes?.leads[item])} ({itemCount[item] ?? <Spinner color="white" style={{ width: "20px", height: "20px" }} />})</p>
                        <div className={`customComponents__kanban__lead  `} >
                            <KanbanStatus agentMode={props.agentMode} onSelect={props.onSelect} country={selectedCountry} customFilters={props.customFilters} status={item} border={props?.headersColors[index]} filters={filters} dateFilters={dateFilters} />
                        </div>
                    </div>
                })
            }


        </div></>
}
const KanbanStatus = (props) => {
    const internalRef = React.useRef();
    const [filters, setFilters] = React.useState([{ name: "Status", op: "eq", value: props.status }]);
    const [specialFilters, setSpecialFilters] = React.useState([]);
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);
    const [totalAmount, setTotalAmount] = React.useState(0);
    let language = useSelector(state => state.language);
    const [orders, setOrders] = React.useState({ name: "updatedAt", order: "desc" });

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const onScreenHandler = useOnScreen();
    const curDefer = useDefer();

    React.useEffect(() => {
        curDefer(() => getData(), 500);
    }, [filters, props.filters, props.dateFilters, orders, props.country, specialFilters]);

    const prepareSpecialFilters = flt => {
        if (!flt) return setSpecialFilters([]);

        if (flt === "produzenja") return setSpecialFilters([
            { name: "isRecurring", op: "eq", value: true }
        ]);
        if (flt === "dodatna-oboljenja") return setSpecialFilters([
            { name: "Name", op: "like", value: "Dodatno oboljenje" }
        ]);
        if (flt === "novi-upit") return setSpecialFilters([
            { name: "--IllnessesExists", value: false },
            { name: "isRecurring", op: "eq", value: false },
            { name: "isSpam", op: "eq", value: false }
        ]);
    };

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();
        setCanPaginate(false);
        let url
        if (props.type === 'deal') {
            url = `${backendModule.backendURL}/deals/getAllDealsDashboard`
        } else {
            url = `${backendModule.backendURL}/leads/getAllLeadsDashboard`
        }
        axios({
            method: "POST",
            url,
            data: {
                pagination: paginationOffset.current,
                limit: 10,
                filters: [
                    props.country ? {
                        name: "Country",
                        op: "eq",
                        value: props.country
                    } : null,
                    ...filters,
                    ...props.customFilters ?? [],
                    ...props.filters ?? [],
                    ...(props.dateFilters ?? []),
                    ...specialFilters
                ],
                orders: [orders],
                smsReminders: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.type === 'deal') {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/deals/getDealsAmount`,
                        data: {
                            filters: [
                                props.country ? {
                                    name: "Country",
                                    op: "eq",
                                    value: props.country
                                } : null,
                                ...filters,
                                ...props.customFilters ?? [],
                                ...props.filters ?? [],
                                ...(props.dateFilters ?? [])
                            ],
                        },
                        ...backendModule.axiosConfig
                    }).then(total => {
                        setTotalAmount(total.data.data)
                    }).catch(() => { })
                }
                if (res.data.data.length === 10) {
                    paginationOffset.current += 10;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = async (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };
        let url
        if (props.type === 'deal') {
            url = `${backendModule.backendURL}/deals/getAllDealsDashboard`
        } else {
            url = `${backendModule.backendURL}/leads/getAllLeadsDashboard`
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url,
            data: {
                pagination: 0,
                limit: 10,
                filters: [
                    { name: "ID", op: "notIn", value: data?.data?.map(t => t.ID) },
                    props.country ? {
                        name: "Country",
                        op: "eq",
                        value: props.country
                    } : null,
                    ...filters,
                    ...props.customFilters ?? [],
                    ...props.filters ?? [],
                    ...(props.dateFilters ?? []),
                    ...specialFilters

                ],
                orders: [orders],
                smsReminders: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 10) {
                    paginationOffset.current += 10;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ]
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const leadHandler = lead => {
        if (!data) return;
        if (data.status === "error") return;

        lead = backendModule.parseData(lead);
        const dataSetter = () => {
            const prepFinalData = d => {
                let finalData = {
                    ...d,
                    data: [
                        (lead.Status === +props.status ? lead : null),
                        ...d.data.filter(td => td.ID !== lead.ID)
                    ].filter(td => td), timestamp: Date.now()
                };
                if (finalData?.data?.length > d?.data?.length) {
                    if (finalData?.data?.length > 10) finalData.data.pop();
                };
                return finalData;
            };

            setData(d => prepFinalData(d));
        };

        if (internalRef.current) {
            let item = internalRef.current.querySelector(`.kanbanLead--leadID-${lead.ID}`);
            if (!item || (item.getAttribute("data-status") === String(lead.Status))) {
                dataSetter();
            } else {
                item.style.overflow = "hidden";
                const computed = getComputedStyle(item);
                const rect = item.getBoundingClientRect();
                item.animate([
                    {
                        maxHeight: `${rect.height}px`,
                        padding: computed.padding,
                        border: computed.border
                    }, {
                        maxHeight: 0,
                        padding: "0px 20px",
                        border: "0px solid transparent"
                    }
                ], {
                    duration: 300,
                    iterations: 1,
                    fill: "both",
                    easing: "ease-in-out"
                }).onfinish = dataSetter
            };
        } else {
            dataSetter();
        };
    }

    React.useEffect(() => {
        if (onScreenHandler.isIntersecting && canPaginate) {
            continueData(curPaginationTimestamp.current);
            onScreenHandler.observer.disconnect();
        };
    }, [onScreenHandler.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let handlerName = props.type === "deal" ? "pingDealChanges" : "pingLeadChanges";
        if (specialFilters.length === 0 && props?.filters?.length === 0 && (props.dateFilters ?? []).length === 0 && !props.country && !(props?.customFilters?.length > 0)) on(handlerName, leadHandler);

        return () => off(handlerName, leadHandler);
    }, [data?.timestamp, filters, props.dateFilters, props.filters])

    return <div ref={internalRef}>
        {props.type === 'deal' && <p style={{
            textAlign: 'center',
            backgroundColor: 'rgb(39, 39, 55)',
            margin: '4px 0',
            borderRadius: '5px',
            boxShadow: `0px 0px 2px ${props.border}, 0px 0px 4px ${props.border}`

        }}>
            {totalAmount && `${Number(totalAmount).toFixed(0).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} €`}</p>}
        {props.type === "deal" && (() => {
            if (data?.data?.length === 0) return null;
            if (props.status === "0") return <CustomButtonSmall value={translate[language].Obradinoveprilike} style={{
                width: "100%",
                height: "32px",
                position: "sticky",
                top: "32px",
                border: "1px solid #6664e5",
                marginBottom: 5
            }} accent="#2b2c3e" onClick={e => {
                animateBox(e, <DealsList Status={0} />);
            }} />
            // if (props.status === "1") return <CustomButtonSmall value={translate[language].Pripremiobraduzaisporuku} style={{
            //     width: "100%",
            //     height: "32px",
            //     position: "sticky",
            //     top: "32px",
            //     border: "1px solid rgb(94, 168, 59)",
            //     marginBottom: 5
            // }} accent="#2b2c3e" onClick={e => {
            //     animateBox(e, <DealsList Status={1} />);
            // }} />
            // if (props.status === "2") return <CustomButtonSmall value={translate[language].Najavipošiljke} style={{
            //     width: "100%",
            //     height: "32px",
            //     position: "sticky",
            //     top: "32px",
            //     border: "1px solid rgb(67, 193, 120)",
            //     marginBottom: 5
            // }} accent="#2b2c3e" onClick={(e) => {
            //     animateBox(e, <DealsList Status={2} />);
            // }} />
            return null;
        })()}
        {!data ? <Spinner align="center" color={props.border} style={{ width: "64px", height: "64px", display: "block" }} /> : <>
            {data.status === "error" ? <p style={{
                textAlign: "center",
                width: "100%",
                fontSize: "14px"
            }}>{translate[language].Došlojedogreškeprilikomdohvatanjapodataka}</p> : <>
                {(props.type !== "deal" && props.status === '0') ? <>
                    <Dropdown style={{ backgroundColor: "rgb(39, 39, 55)" }} theme="dark" accent={props.border} data={[
                        { name: "Svi leadovi", value: null },
                        { name: "Samo novi upit", value: "novi-upit" },
                        { name: "Produženja", value: "produzenja" },
                        { name: "Dodatna oboljenja", value: "dodatna-oboljenja" }
                    ]} selected={0} onChange={e => prepareSpecialFilters(e?.value)} />
                </> : <>
                    <Dropdown style={{ backgroundColor: "rgb(39, 39, 55)" }} theme="dark" accent={props.border} data={[
                        { name: translate[language].Datumpromjeneuzlazno, value: { name: "updatedAt", order: "asc" } },
                        { name: translate[language].Datumpromjenesilazno, value: { name: "updatedAt", order: "desc" } },
                        { name: translate[language].Datumkreiranjauzlazno, value: { name: "updatedAt", order: "asc" } },
                        { name: translate[language].Datumkreiranjasilazno, value: { name: "updatedAt", order: "desc" } },
                        { name: translate[language].Datumzadnjeobradeuzlazno, value: { name: "agentUpdatedAt", order: "asc" } },
                        { name: translate[language].Datumzadnjeobradesilazno, value: { name: "agentUpdatedAt", order: "desc" } },
                        { name: translate[language].IDuzlazno, value: { name: "ID", order: "asc" } },
                        { name: translate[language].IDsilazno, value: { name: "ID", order: "desc" } },
                        { name: translate[language].Nazivuzlazno, value: { name: "Name", order: "asc" } },
                        { name: translate[language].Nazivsilazno, value: { name: "Name", order: "desc" } },
                        { name: translate[language].Državauzlazno, value: { name: "Country", order: "asc" } },
                        { name: translate[language].Državasilazno, value: { name: "Country", order: "desc" } },
                        { name: translate[language].Cijenauzlazno, value: { name: "Amount", order: "asc" } },
                        { name: translate[language].Cijenasilazno, value: { name: "Amount", order: "desc" } },
                        { name: translate[language].Brojpozivauzlazno, value: { name: "CallAttempts", order: "asc" } },
                        { name: translate[language].Brojpozivasilazno, value: { name: "CallAttempts", order: "desc" } }
                    ]} selected={1} onChange={e => setOrders(e?.value)} />
                </>}

                {data.data.map((item, index) => {
                    return <KanbanLead agentMode={props.agentMode} measureRef={index === data.data.length - 1 ? onScreenHandler.measureRef : null} onSelect={props.onSelect} data={item} border={props.border} key={`kanban-lead-${item.ID}`} type={props.type} />
                })}
                {secondarySpinner && <Spinner align="center" color={props.border} style={{ width: "64px", height: "64px", display: "block" }} />}
            </>}
        </>}
    </div>
}

const KanbanLead = (props) => {
    const internalRef = React.useRef();
    const chatUsers = useSelector((state) => state.chatUsers);
    const shipmentStatusCodes = useSelector(state => state?.statusCodes?.shipment ?? null);
    const dealSaveStatusCodes = useSelector(state => state?.statusCodes?.dealSaves ?? null);
    const smsUnpaidRemindersCodes = useSelector(state => state?.statusCodes?.unpaidReminders ?? {});
    let language = useSelector(state => state.language);

    const [userImg, setUserImg] = React.useState()
    const [dealUserImg, setDealUserImg] = React.useState()
    const [userImgBeforeReject, setUserImgBeforeReject] = React.useState();
    const [data, setData] = React.useState(props.data)
    const userSelector = useSelector((state) => state.userData.userData.UserInfo);

    const trimClientName = client => {
        let tmp = client.split(" ");
        if (tmp.length > 1) {
            tmp = tmp.map((tItem, tIndex) => {
                if (tIndex === 0) return tItem;
                return `${tItem.charAt(0)}.`;
            });
        };

        return tmp.join(" ");
    };

    React.useEffect(() => {
        if (!internalRef.current) return;
        if (!props.border) return;
        internalRef.current.style.overflow = "hidden";
        let computed = getComputedStyle(internalRef.current);
        internalRef.current.animate([
            {
                maxHeight: computed.maxHeight ?? "0px",
                padding: computed.padding ?? "0px 20px",
                borderRight: "2px solid transparent",
                borderBottom: "2px solid transparent"
            },
            {
                maxHeight: "500px",
                padding: "20px 20px",
                borderRight: "2px solid transparent",
                borderBottom: "2px solid transparent"
            }
        ], {
            duration: 300,
            iterations: 1,
            easing: "ease-in-out",
            fill: "both"
        }).onfinish = () => {
            if (!internalRef.current) return;
            internalRef.current.style.overflow = "auto";
            internalRef.current.animate([
                {
                    maxHeight: "none",
                    borderRight: "2px solid transparent",
                    borderBottom: "2px solid transparent"
                },
                {
                    maxHeight: "none",
                    borderRight: `2px solid ${props.border}`,
                    borderBottom: `2px solid ${props.border}`
                }
            ], {
                duration: 300,
                iterations: 1,
                easing: "linear",
                fill: "both"
            })
        };
    }, [internalRef]);

    React.useEffect(() => {
        if (data.ResponsiblePerson) {
            for (let i = 0; i < chatUsers.length; i++) {
                if (chatUsers[i].ID === data.ResponsiblePerson) {
                    setUserImg(chatUsers[i].Image);
                };
                if (chatUsers[i].ID === data.DealResponsiblePerson) {
                    setDealUserImg(chatUsers[i].Image);
                };
            };
        };
        if (data.ResponsiblePersonBeforeReject) {
            let cImg = chatUsers.find(t => t.ID === data.ResponsiblePersonBeforeReject);
            if (cImg) setUserImgBeforeReject(cImg?.Image);
        };
    }, [data]);

    React.useEffect(() => {
        setData(props.data);
    }, [props.data]);

    let dealShipColor = null;
    if (data.ShippmentStatus !== null && data.ShippmentStatus !== undefined) {
        dealShipColor = getDealShipColor(Number(data.ShippmentStatus));
    };

    let dealSaveShipColor = null;
    if (data.DealSaveStatus !== null && data.DealSaveStatus !== undefined) {
        dealSaveShipColor = getDealSaveShipColor(Number(data.DealSaveStatus));
    };

    let recurringPaymentColor = "";
    let recurringPaymentCode = 1;
    let recurringPaymentStatus = "Nije preuzeto";
    if (data.isRecurringPayment) {
        if (props.type !== "deal") {
            recurringPaymentColor = getRecurringPaymentColor(null);
        } else {
            if (data.Status !== 100) {
                recurringPaymentColor = getRecurringPaymentColor(null);
            } else {
                recurringPaymentColor = getRecurringPaymentColor(data.isRecurringPaymentPaid);
                recurringPaymentCode = data.isRecurringPaymentPaid ? 3 : 2;
                recurringPaymentStatus = data.isRecurringPaymentPaid ? "2 rata uplaćena" : "1 rata uplaćena";
            };
        };
    };

    return <div key={`kanbanLead kanbanLead--leadID-${props.data.ID}`} ref={internalRef} data-status={String(props.data.Status)} className={`kanbanLead kanbanLead--leadID-${props.data.ID}`} style={{ maxHeight: "0" }}>
        <p ref={props.measureRef} style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: 'gray', marginBottom: '10px' }}>{translate[language].Kreiran}: {moment(props.data.createdAt).format('DD-MM-YYYY HH:mm')}</p>
        {props.type !== "deal" && <p style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: 'gray', marginBottom: '10px' }}>{translate[language].Zadnjaobrada}: {moment(props.data.agentUpdatedAt).format('DD-MM-YYYY HH:mm')}</p>}
        {props.type === "deal" && <p style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: 'gray', marginBottom: '10px' }}>{translate[language].Zadnjapromjena}: {moment(props.data.updatedAt).format('DD-MM-YYYY HH:mm')}</p>}
        {data.isSpam && <p style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: '#fd8f8f', marginBottom: '10px' }}>Dupli/Spam detektovan!</p>}
        {(props.type === "deal" && data.PotentialDuplicateDeal) && <p style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: '#fd8f8f', marginBottom: '10px' }}>Potencijalni dupli deal!</p>}
        {(props.type === "deal" && data.PotentialWrongProduct) && <p style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: '#fd8f8f', marginBottom: '10px' }}>Potencijalni pogresan proizvod!</p>}
        {data.isPlarityBrand && <p style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: 'rgb(229, 143, 253)', marginBottom: '10px' }}>Brend: Plarity</p>}
        {data.SubTypeName && <p style={{ width: '100%', textAlign: 'left', margin: 'auto', fontSize: '12px', color: 'rgb(229, 143, 253)', marginBottom: '10px' }}>Prilagođeni tip: {data.SubTypeName}</p>}
        <p style={{ fontSize: '18px', maxHeight: '65px', overflow: 'hidden', wordWrap: "break-word" }}>ID: {data.ID} - {props.data.Name}</p>
        <small>{translate[language].Imeklijenta}</small>
        <p style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            lineClamp: 2,
            wordBreak: "break-word",
            WebkitBoxOrient: "vertical"
        }}>{props.agentMode ? trimClientName(data.FirstLastName) : data.FirstLastName}</p>
        {!props.agentMode && <>
            <small>{translate[language].Brojtelefona}</small>
            {data.PhoneNumber.length > 7 ? <p>{data.PhoneNumber.substring(0, 6) + "*".repeat(data.PhoneNumber.length - 7)}</p> :
                <p>{data.PhoneNumber.substring(0, 6)}</p>}
        </>}
        {(userSelector?.Flags?.isAdmin || userSelector?.Flags?.isCCManager) && <> <small>{translate[language].Odgovorniagent}</small>
            <p style={{ 'display': 'flex', 'alignItems': 'center' }} >{data.ResponsiblePerson && <img src={userImg ? userImg : '/images/undefined.png'} alt=""
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/undefined.png";
                }}
            />}  {props.data.ResponsiblePersonName}</p>

            {props.type !== "deal" && <>
                {data.ResponsiblePersonBeforeReject && <>
                    <small>{translate[language].Odgovorniagentprijezadnješanse}</small>
                    <p style={{ 'display': 'flex', 'alignItems': 'center' }} >{data.ResponsiblePersonBeforeReject && <img src={userImgBeforeReject ? userImgBeforeReject : '/images/undefined.png'} alt=""
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/undefined.png";
                        }}
                    />}  {props.data.ResponsiblePersonBeforeRejectName}</p>
                </>}
            </>}
        </>}

        {props.type === "deal" && <>
            {data.DealResponsiblePerson && <>
                <small>Odgovorna problematika</small>
                <p style={{ 'display': 'flex', 'alignItems': 'center' }} >{data.DealResponsiblePerson && <img src={dealUserImg ? dealUserImg : '/images/undefined.png'} alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/images/undefined.png";
                    }}
                />}  {props.data.DealResponsiblePersonName}</p>
            </>}
        </>}
        {(props.type === "deal" && data.Status === 5000) && <>
            <small>Datum dostave</small>
            <p>{new Date(data.ScheduledDelivery).toLocaleDateString()}</p>
        </>}
        {(props.type === "deal" && data.Status === 100) && <>
            <small>Razdužen</small>
            <p>{data.DealMoneyReceived ? <span style={{ color: "rgb(149, 255, 205)" }}>Da</span> : <span style={{ color: "rgb(255, 149, 149)" }}>Ne</span>}</p>
        </>}
        {!props.agentMode && <CustomButtonSmall style={{ 'marginTop': '10px', border: "1px solid #999FAE", display: "block" }} value={translate[language].Detalji} accent='#2b2c3e' onClick={(e) => { animateBox(e, <SingleLead data={data} type={props.type} />) }} />}
        {props.onSelect && <CustomButtonSmall style={{ 'marginTop': '10px', border: "1px solid #E264E59E", display: "block" }} value={`Odaberi ${props.type === "deal" ? "deal" : "lead"}`} accent='#2b2c3e' onClick={(e) => {
            props.onSelect(data);
        }} />}
        {(data.ShippmentStatus !== null && data.ShippmentStatus !== undefined) && <div className="kanbanLead__dealShipment">
            <p>{shipmentStatusCodes[data.ShippmentStatus] ?? "Nepoznat status"}</p>
            <div style={{ gridTemplateColumns: `repeat(${Object.keys(shipmentStatusCodes).length}, 1fr)` }}>
                {Object.keys(shipmentStatusCodes).map(key => {
                    return <span style={{
                        backgroundColor: key <= data.ShippmentStatus ? dealShipColor : null
                    }}></span>
                })}
            </div>
        </div>}

        {((data.DealResponsiblePerson && data.DealSaveStatus !== null && data.DealSaveStatus !== undefined) || data.DealSaveStatus === 2) && <div className="kanbanLead__dealShipment">
            <p>Problematika: {dealSaveStatusCodes[data.DealSaveStatus] ?? "Nepoznat status"}</p>
            <div style={{ gridTemplateColumns: `repeat(${Object.keys(dealSaveStatusCodes).length}, 1fr)` }}>
                {Object.keys(dealSaveStatusCodes).map(key => {
                    return <span style={{
                        backgroundColor: key <= data.DealSaveStatus ? dealSaveShipColor : null
                    }}></span>
                })}
            </div>
        </div>}

        {moment(data.ScheduledCallTime).isAfter() && <>
            <small>Poziv odgođen za:</small>
            <p style={{ color: "rgb(59, 166, 147)" }}>{moment(data.ScheduledCallTime).toDate().toLocaleString()}</p>
        </>}

        {(data.isRecurringPayment && data.isRecurringPaymentJMBG) && <div className="kanbanLead__dealShipment">
            <small>Odgođeno plaćanje: {recurringPaymentStatus}</small>
            <div style={{ gridTemplateColumns: `repeat(3, 1fr)` }}>
                {[1, 2, 3].map((key) => {
                    return <span style={{
                        backgroundColor: (recurringPaymentCode >= key) ? recurringPaymentColor : null
                    }}></span>
                })}
            </div>
        </div>}

        {(data.smsReminder !== null && data.smsReminder !== undefined) && <div className="kanbanLead__dealShipment">
            <p>Upozorenje: {smsUnpaidRemindersCodes[data.smsReminder] ?? "Nepoznat status"}</p>
            <div style={{ gridTemplateColumns: `repeat(${Object.keys(smsUnpaidRemindersCodes).length}, 1fr)` }}>
                {Object.keys(smsUnpaidRemindersCodes).map(key => {
                    return <span style={{
                        backgroundColor: key <= data.smsReminder ? getUnpaidRemindersStatusColor(Number(key)) : null
                    }}></span>
                })}
            </div>
        </div>}
    </div>
}


const KanbanDate = props => {
    const [selectedPeriod, setSelectedPeriod] = React.useState();
    const [pickerActive, setPickerActive] = React.useState(false);
    const [dateType, setDateType] = React.useState("createdAt");

    const handleOnChange = (ranges) => {
        setSelectedPeriod([ranges?.selection]);
    };

    React.useEffect(() => {
        if (pickerActive) return;

        if (props.onChange) {
            let s = selectedPeriod;
            if (Array.isArray(s)) {
                s.map(ss => {
                    if (typeof (ss) === "object") {
                        ss.dateType = dateType;
                    };
                    return ss;
                })
            };
            props.onChange(s);
        };
    }, [selectedPeriod, pickerActive, dateType]);

    return <div className="customComponents__kanban__customDate" onClick={e => {
        e.stopPropagation();
        setPickerActive(p => !p);
    }}>
        <div className="customComponents__kanban__customDate__selected">
            {selectedPeriod ?
                <>
                    {`${selectedPeriod[0].startDate.toLocaleDateString()} - ${selectedPeriod[0].endDate.toLocaleDateString()}`}
                    <img src="/images/close.png" onClick={e => {
                        e.stopPropagation();
                        setSelectedPeriod(undefined);
                    }} />
                </> :
                "Odaberi datum"
            }
        </div>

        <div style={{ maxHeight: pickerActive ? "1000px" : "0px", boxShadow: pickerActive ? null : "none" }} className="customComponents__kanban__customDate__picker" onClick={e => e.stopPropagation()}>
            <div className="customComponents__kanban__customDate__picker__buttons">
                <CustomButtonSmall value="Datum kreiranja" theme="dark" accent={dateType === "createdAt" ? "#3d91ff" : "#fff"} onClick={() => {
                    if (dateType !== "createdAt") setDateType("createdAt")
                }} />
                <CustomButtonSmall value="Datum obrade" theme="dark" accent={dateType === "agentUpdatedAt" ? "#3d91ff" : "#fff"} onClick={() => {
                    if (dateType !== "agentUpdatedAt") setDateType("agentUpdatedAt");
                }} />
            </div>
            <DateRangePicker
                onChange={handleOnChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={selectedPeriod || [{
                    startDate: moment().startOf("day").toDate(),
                    endDate: moment().startOf("day").toDate(),
                    key: "selection"
                }]}
                direction="horizontal"
            />
        </div>
    </div>
};


export { Kanban }