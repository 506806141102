import React from "react";
import axios from "axios";
import "./index.scss";
import { useDispatch, useSelector, Provider } from "react-redux";
import Dropdown from "../customComponents/Dropdown";
import CustomInput from "../../components/customComponents/CustomInput";
import CustomButtonSmall from "../customComponents/ButtonSmall";
import * as backendModule from '../../modules/backendModule'
import { updateActivity } from '../../actions/activityAction'
import offers from "../../offers.json";

const CreateLead = (props) => {
    const internalRef = React.useRef();
    let userData = useSelector((state) => state.userData)
    let userDispatch = useDispatch();

    const [allCountries, setAllCountries] = React.useState([]);
    const [allCities, setAllCities] = React.useState([]);
    const [buyerCity, setBuyerCity] = React.useState('');
    const [buyerCountry, setBuyerCountry] = React.useState('');
    const [product, setProduct] = React.useState();

    const leadName = React.useRef('')
    const firstLastName = React.useRef('');
    const phoneNumber = React.useRef('');
    const saveMessage = React.useRef('');

    const onClose = () => {
        if (!internalRef.current) return props.onClose();
        internalRef.current.animate([
            { opacity: getComputedStyle(internalRef.current).opacity },
            { opacity: 0 }
        ], {
            duration: 200,
            delay: 0,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out"
        });
        props.onClose();
    };

    const getCities = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllCities`,
            data: {
                Country: buyerCountry
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllCities(res.data.data)
            };
        }).catch(() => null);
    };
    const getCountries = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                setAllCountries(res.data.data);
            } else {
                setTimeout(() => getCountries(), 1000);
            };
        }).catch(() => {
            setTimeout(() => getCountries(), 1000);
        });
    };

    React.useEffect(() => {
        if (!buyerCountry) return;
        if (!allCountries) return;
        if (allCountries.length === 0) return;
        getCities();
    }, [buyerCountry, allCountries]);

    React.useEffect(() => {
        getCountries()
    }, [])

    React.useEffect(() => {
        if (!internalRef.current) return;
        internalRef.current.animate([
            { opacity: getComputedStyle(internalRef.current).opacity },
            { opacity: 1 }
        ], {
            duration: 300,
            delay: 100,
            iterations: 1,
            fill: "both",
            easing: "ease-in-out"
        });
    }, [internalRef.current]);

    const saveData = () => {
        if (firstLastName.current.value === '' || phoneNumber.current.value === '' || !product) {
            saveMessage.current.innerHTML = 'Ime kupca, proizvod i broj telefona ne smiju biti prazni!';
            saveMessage.current.style.color = 'red'
            return
        }
        let data = {
            authUser: userData.userData.UserInfo.ID,
            Name: `${product.name} ${props.head === "order" ? "" : "- dolazni poziv"}`,
            Amount: null,
            FirstLastName: firstLastName.current.value,
            PhoneNumber: phoneNumber.current.value,
            City: buyerCity,
            Country: buyerCountry,
            Status: 1,
            AdditionalData: {
                OfferID: product.id,
                AdvertiserID: product.brand === "Nutri Health" ? "0b8b3436-a520-4903-ac74-6e91134c3d98" : null
            }
        }
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/leads/addLead`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                if (props.setTestLead) {
                    props.setTestLead(res.data.data)
                }
                saveMessage.current.innerHTML = `Lead je uspiješno kreiran (ID: ${res.data.data})`;
                saveMessage.current.style.color = 'green'
                setTimeout(() => {
                    if (typeof (props.onChange) === "function") {
                        props.onChange(res.data.data);
                    };
                    onClose()
                }, 0)
            } else {
                saveMessage.current.innerHTML = `Došlo je do greške prilikom kreiranja leada`;
                saveMessage.current.style.color = 'red'
            }
        })
    }

    return <div className="component__createLead" ref={internalRef}>
        <div className="component__createLead__head">
            <img src="/images/closeImg.svg" onClick={onClose} />
        </div>
        <h1 style={{ color: "white" }}>Kreiraj novi lead <img src="/images/rightArrow.svg"></img></h1>
        <div className="component__createLead__dropdown" style={{ 'width': '100%', marginBottom: "20px" }} >
            <Dropdown theme='dark' accent="#fff" data={offers.map(offer => {
                return {
                    name: offer[0], value: {
                        name: offer[0],
                        id: offer[1],
                        brand: offer[2]
                    }
                }
            })} inlinePlaceholder='Izaberite Proizvod'
                onChange={(e) => {
                    setProduct(e?.value)
                }} style={{ boxShadow: "none", border: "none", borderBottom: "1px solid #fff", borderRadius: "0px", padding: 0, paddingBottom: "5px", fontSize: "16px" }} />
        </div>
        <CustomInput defaultValue={props.FirstLastName ?? ""} theme="dark" accent="#fff" style={{ width: "100%", marginBottom: "20px" }} placeholder="Ime i prezime kupca" ref={firstLastName} />
        <CustomInput defaultValue={props.phoneNumber ?? ""} theme="dark" accent="#fff" style={{ width: "100%", marginBottom: "20px" }} placeholder="Broj telefona kupca" ref={phoneNumber} />
        <div className="component__createLead__dropdown" style={{ 'width': '100%', marginBottom: "20px" }} >
            <Dropdown theme='dark' accent="#fff" data={allCountries.map(country => {
                return { name: country.CountryName, value: country.CountryCode }
            })} inlinePlaceholder='Izaberite državu'
                onChange={(e) => {
                    setBuyerCountry(e?.value)
                }} style={{ boxShadow: "none", border: "none", borderBottom: "1px solid #fff", borderRadius: "0px", padding: 0, paddingBottom: "5px", fontSize: "16px" }} />
        </div>
        <div className="component__createLead__dropdown" style={{ 'width': '100%' }}>
            <Dropdown theme='dark' accent="#fff" limit={500}
                data={allCities.map((item) => {
                    return { name: `${item.Name} ${item.PttNo}`, value: item.CityID }
                })} inlinePlaceholder='Izaberite grad' onChange={(e) => {
                    setBuyerCity(e?.value)
                }} style={{ boxShadow: "none", border: "none", borderBottom: "1px solid #fff", borderRadius: "0px", padding: 0, paddingBottom: "5px", fontSize: "16px" }} />
        </div>
        <p ref={saveMessage} ></p>
        <div className="component__createLead__buttons" >
            <CustomButtonSmall value='Otkaži' accent='#2E2E41' onClick={() => { onClose() }} style={{ border: "1px solid rgb(233, 86, 86)" }} />
            <CustomButtonSmall value='Spremi' accent='#2E2E41' onClick={() => { saveData() }} style={{ border: "1px solid #999FAE", marginLeft: "30px" }} />
        </div>


    </div>
}
export default CreateLead